import { usePathname, useSearchParams } from 'next/navigation';
import { PostHogProvider, usePostHog } from 'posthog-js/react';
import React, { Suspense, useContext, useEffect } from 'react';

import { AppLanguage } from 'Helpers/strings';
import { posthogEnabled, posthogHost, posthogKey } from 'Src/config';

import { AppContext } from './AppContext';

const UNTRACKED_PAGES_LIST = ['/callback'];

function PostHogTracking(): null {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const posthog = usePostHog();
  const { userAccessToken } = useContext(AppContext);

  useEffect(() => {
    if (userAccessToken) {
      posthog.identify(userAccessToken.userId, {
        role: userAccessToken.role,
        accountId: userAccessToken.userId,
        name: userAccessToken.name,
        companyName: userAccessToken.tenantName,
        tenantId: userAccessToken.tenantId,
        tenantType: userAccessToken.tenantType,
        tier: userAccessToken.tenantTier,
        email: userAccessToken.email,
        language: AppLanguage.get(),
      });
      posthog.group('tenant', userAccessToken.tenantId, {
        companyName: userAccessToken.tenantName,
        tenantId: userAccessToken.tenantId,
        tenantType: userAccessToken.tenantType,
        tier: userAccessToken.tenantTier,
      });
    }
  }, [userAccessToken, posthog]);

  // Track pageviews
  useEffect(() => {
    if (pathname && posthog) {
      if (UNTRACKED_PAGES_LIST.includes(pathname)) {
        return;
      }

      posthog.capture('$pageview', { $current_url: window.location.href });
    }
  }, [pathname, searchParams, posthog]);

  return null;
}

function SuspendedPostHogTracking(): React.ReactNode {
  return (
    <Suspense fallback={null}>
      <PostHogTracking />
    </Suspense>
  );
}

export const Posthog: React.FC<Required<React.PropsWithChildren<unknown>>> = (
  props,
) => {
  const options = {
    api_host: posthogHost,
    capture_pageview: false, // Disable automatic pageview capture, as we capture manually
  };
  return posthogEnabled ? (
    <PostHogProvider apiKey={posthogKey} options={options}>
      <SuspendedPostHogTracking />
      {props.children}
    </PostHogProvider>
  ) : (
    props.children
  );
};
